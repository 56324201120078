function setEqualHeight() {
    var skelSize = getNumSkelSize();
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .grid-element-content").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product").matchHeight();
    //$(".grid-product .nakup").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
    $(".trademark-content").matchHeight();
    $(".grid-product-017-03").matchHeight({
        byRow: false
    });
    $(".slick-slider .grid-product .grid-element-title").matchHeight({
        byRow: false
    });
    $(".slick-slider .grid-product .prices-small").matchHeight({
        byRow: false
    });
    if (skelSize > 3) {
        $("#main-left").css("height", "");
        if ($("#main-left").outerHeight < $("#main-center").outerHeight) {
            $("#main-left").matchHeight({
                target: $("#main-center")
            });
        }
    }

}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 50,
        scroll_limit = 150,
        initial_height = 100,
        target_height = 80,
        initial_padding = 10,
        target_padding = 5,
        initial_margin_top = 0,
        target_margin_top = -40,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 80;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
        case "xxlarge":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 80;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
        case "large":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 80;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
        case "medium":
            scroll_limit_1 = 40;
            scroll_limit = 120;
            initial_height = 80;
            target_height = 60;
            initial_padding = 8;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "small":
            scroll_limit_1 = 40;
            scroll_limit = 120;
            initial_height = 80;
            target_height = 60;
            initial_padding = 8;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "xsmall":
            scroll_limit_1 = 40;
            scroll_limit = 120;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        default:
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 80;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
    }
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            margin_top = initial_margin_top
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            margin_top = initial_margin_top - $(window).scrollTop();
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            // padding = initial_padding * ((scroll_limit - scroll_limit_1) - ($(window).scrollTop() - scroll_limit_1)) / (scroll_limit - scroll_limit_1);
            padding = initial_padding - (initial_padding - target_padding) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            logo_height = initial_height - (initial_height - target_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = target_padding;
            logo_height = target_height;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").hide();
                $("#logo-scroll").show();
            }
            */
        }
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");

        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
        }
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(function() {
    $("#main-menu-mobile").smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport: true,
        keepHighlighted: false,
        markCurrentItem: false,
        markCurrentTree: false
        /*}).bind("click.smapi", function(e, item) {
            var $item = $(item);
            if($item.attr("href") === "#") {
                e.preventDefault();
                $('#main-menu').smartmenus('itemActivate', $item);
                return false;
            }*/
    });
});

$('#main-menu-state-mobile').change(function(e) {
    var $menu = $('#main-menu-mobile');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu-mobile").offset().top - $(window).scrollTop()));
                $('#main-menu-mobile').css("max-height", maxHeight + "px");
                if ($('#main-menu-mobile').outerHeight() > maxHeight) {
                    $('#main-menu-mobile').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu-mobile').css("max-height", "");
            $('#main-menu-mobile').css("height", "");
        });
    }
});

$('#main-menu-state-left').change(function(e) {
    var $menu = $('#main-menu-left');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu-left").offset().top - $(window).scrollTop()));
                $('#main-menu-left').css("max-height", maxHeight + "px");
                if ($('#main-menu-left').outerHeight() > maxHeight) {
                    $('#main-menu-left').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu-left').css("max-height", "");
            $('#main-menu-left').css("height", "");
        });
    }
});

$(function() {
    $("#main-menu-left").smartmenus({
        mainMenuSubOffsetX: 1,
        mainMenuSubOffsetY: -8,
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport: true,
        keepHighlighted: false,
        markCurrentItem: false,
        markCurrentTree: false
        /*}).bind("click.smapi", function(e, item) {
            var $item = $(item);
            if($item.attr("href") === "#") {
                e.preventDefault();
                $('#main-menu').smartmenus('itemActivate', $item);
                return false;
            }*/
    });
});

$('#main-menu-state-left').change(function(e) {
    var $menu = $('#main-menu-left');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu-left").offset().top - $(window).scrollTop()));
                $('#main-menu-left').css("max-height", maxHeight + "px");
                if ($('#main-menu-left').outerHeight() > maxHeight) {
                    $('#main-menu-left').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu-left').css("max-height", "");
            $('#main-menu-left').css("height", "");
        });
    }
});
